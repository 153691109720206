import { FC } from 'react';
import { ClientFormUser } from '../../../models/ClientFormUser';
import { MinimalUser } from '../../../models/User';
import { Option } from '../../Option';
import { ImageSize, ProfileImageStack } from '../../ownership/ProfileImageStack';

const CommentListRenderer: (users: MinimalUser[]) => FC<Option<string, string | number>> = (users: MinimalUser[]) => {
  const Renderer: FC<Option<string, string | number>> = (props) => {
    const user = users.find((x) => x.id === props.id) as ClientFormUser;
    return (
      <div className="flex items-center">
        <ProfileImageStack size={ImageSize.S} users={[user]} />
        <div className="ml-3 font-medium text-black">{props.text}</div>
      </div>
    );
  };

  return Renderer;
};

export default CommentListRenderer;
